.homepage-search.v4 {
    .first-col {
        input {
            width: 100%;
            padding: 0 8px;
        }
    }

    &.search-selects {
        .input-column {

            input {
                width: 94px
            }
        }

        .select-column {
            width: auto;
            height: 50px;

            .SumoSelect {
                > .CaptionCont {
                    border: none;
                    /*margin-left: 1px;*/
                    width: auto;
                    text-align: left;
                }
            }
        }

        .range-col {
            max-width: 9.28%;
            flex: 0 0 9.28%;
        }
    }

    .select-column-search {
        padding-left: 20px;
        margin: auto;
        width: 50%;
    }

    .no-gutters {
        text-align: left;
    }

    .col-type {
        width: 158px !important;
    }

    .col-narrower {
        width: 183px !important

    }

    .col-county {
        width: 202px !important;
    }

    .col-city {
        width: 155px !important;
    }


    .range-col {
        padding: 0 !important;

        @media (max-width: 1170px) {
            p {
                padding: 0 5px;
            }
        }
    }
}




.homepage-group.v4 {
    .input-group{
        div{
            border-right: 1px solid #d4d4d4;
        }
    }

    .first-col {
        input {
            padding: 0 5px;
            width: 91px !important;
            border-left: 1px solid #d4d4d4;
        }
    }

    &.search-selects {
        .input-column {

            input {
                width: 94px
            }
        }

        .select-column {
            width: auto;
            height: 50px;

            .SumoSelect {
                > .CaptionCont {
                    border: none;
                    /*margin-left: 1px;*/
                    width: auto;
                    text-align: left;
                }
            }
        }

        .range-col {
            max-width: 9.28%;
            flex: 0 0 9.28%;
        }
    }

    .select-column-search {
        padding-left: 20px;
        margin: auto;
        width: 50%;
    }

    .no-gutters {
        text-align: left;
    }

    .col-type {
        width: 150px !important;
    }

    .col-narrower {
        width: 189px !important
    }

    .col-county {
        width: 183px !important;
    }

    .col-city {
        width: 155px !important;
    }

    .range-col {
        padding: 0 !important;

        @media (max-width: 1170px) {
            p {
                padding: 0 5px;
            }
        }
    }
}







